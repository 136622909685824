const MATCH_STATUS_GENERAL = {
  // 0-19
  NOT_STARTED: 0,
  FINAL: 1,
  DELAYED: 2,
  CANCELLED: 3,
  OTHER: 4,
  INTERRUPTED: 5
};

const FOOTBALL_MATCH_STATUS = {
  ...MATCH_STATUS_GENERAL,
  // 20-39
  FIRST_HALF: 20,
  HALFTIME: 21,
  SECOND_HALF: 22,
  AWAITING_EXTRA_TIME: 23,
  FIRST_EXTRA_TIME: 24,
  HALFTIME_EXTRA_TIME: 25,
  SECOND_EXTRA_TIME: 26,
  FINAL_EXTRA_TIME: 27,
  AWAITING_PENALTIES: 28,
  PENALTIES: 29,
  FINAL_PENALTIES: 30
};

const AMERICAN_FOOTBALL_MATCH_STATUS = {
  ...MATCH_STATUS_GENERAL,
  // 40-59
  FIRST_QUARTER: 40,
  END_FIRST_QUARTER: 41,
  SECOND_QUARTER: 42,
  HALFTIME: 43,
  THIRD_QUARTER: 44,
  END_THIRD_QUARTER: 45,
  FOURTH_QUARTER: 46,
  AWAITING_OVERTIME: 47,
  OVERTIME: 48,
  FINAL_OVERTIME: 49
};

const LABELS = {
  [MATCH_STATUS_GENERAL.NOT_STARTED]: '',
  [MATCH_STATUS_GENERAL.FINAL]: 'Fim',
  [MATCH_STATUS_GENERAL.DELAYED]: 'Adiado',
  [MATCH_STATUS_GENERAL.CANCELLED]: 'Cancel',
  [MATCH_STATUS_GENERAL.OTHER]: 'Outro',
  [MATCH_STATUS_GENERAL.INTERRUPTED]: 'Interr',
  [FOOTBALL_MATCH_STATUS.FIRST_HALF]: '1T',
  [FOOTBALL_MATCH_STATUS.HALFTIME]: 'Int',
  [FOOTBALL_MATCH_STATUS.SECOND_HALF]: '2T',
  [FOOTBALL_MATCH_STATUS.AWAITING_EXTRA_TIME]: 'TE',
  [FOOTBALL_MATCH_STATUS.FIRST_EXTRA_TIME]: '1TE',
  [FOOTBALL_MATCH_STATUS.HALFTIME_EXTRA_TIME]: 'Int TE',
  [FOOTBALL_MATCH_STATUS.SECOND_EXTRA_TIME]: '2TE',
  [FOOTBALL_MATCH_STATUS.FINAL_EXTRA_TIME]: 'Fim TE',
  [FOOTBALL_MATCH_STATUS.AWAITING_PENALTIES]: 'Pen',
  [FOOTBALL_MATCH_STATUS.PENALTIES]: 'Pen',
  [FOOTBALL_MATCH_STATUS.FINAL_PENALTIES]: 'Fim Pen',
  [AMERICAN_FOOTBALL_MATCH_STATUS.FIRST_QUARTER]: '1Q',
  [AMERICAN_FOOTBALL_MATCH_STATUS.END_FIRST_QUARTER]: 'Fim 1Q',
  [AMERICAN_FOOTBALL_MATCH_STATUS.SECOND_QUARTER]: '2Q',
  [AMERICAN_FOOTBALL_MATCH_STATUS.HALFTIME]: 'HT',
  [AMERICAN_FOOTBALL_MATCH_STATUS.THIRD_QUARTER]: '3Q',
  [AMERICAN_FOOTBALL_MATCH_STATUS.END_THIRD_QUARTER]: 'Fim 3Q',
  [AMERICAN_FOOTBALL_MATCH_STATUS.FOURTH_QUARTER]: '4Q',
  [AMERICAN_FOOTBALL_MATCH_STATUS.AWAITING_OVERTIME]: 'OT',
  [AMERICAN_FOOTBALL_MATCH_STATUS.OVERTIME]: 'OT',
  [AMERICAN_FOOTBALL_MATCH_STATUS.FINAL_OVERTIME]: 'Fim OT'
};

const STOPPED_GAME = [
  MATCH_STATUS_GENERAL.NOT_STARTED,
  MATCH_STATUS_GENERAL.FINAL,
  MATCH_STATUS_GENERAL.DELAYED,
  MATCH_STATUS_GENERAL.CANCELLED,
  MATCH_STATUS_GENERAL.OTHER,
  MATCH_STATUS_GENERAL.INTERRUPTED,
  FOOTBALL_MATCH_STATUS.HALFTIME,
  FOOTBALL_MATCH_STATUS.AWAITING_EXTRA_TIME,
  FOOTBALL_MATCH_STATUS.HALFTIME_EXTRA_TIME,
  FOOTBALL_MATCH_STATUS.FINAL_EXTRA_TIME,
  FOOTBALL_MATCH_STATUS.AWAITING_PENALTIES,
  FOOTBALL_MATCH_STATUS.FINAL_PENALTIES,
  AMERICAN_FOOTBALL_MATCH_STATUS.END_FIRST_QUARTER,
  AMERICAN_FOOTBALL_MATCH_STATUS.HALFTIME,
  AMERICAN_FOOTBALL_MATCH_STATUS.END_THIRD_QUARTER,
  AMERICAN_FOOTBALL_MATCH_STATUS.AWAITING_OVERTIME,
  AMERICAN_FOOTBALL_MATCH_STATUS.FINAL_OVERTIME
];

const MATCH_STATUS = {
  FINAL: MATCH_STATUS_GENERAL.FINAL,
  FINAL_OVERTIME: AMERICAN_FOOTBALL_MATCH_STATUS.FINAL_OVERTIME,
  HALFTIME: AMERICAN_FOOTBALL_MATCH_STATUS.HALFTIME,
  END_FIRST: AMERICAN_FOOTBALL_MATCH_STATUS.END_FIRST_QUARTER,
  END_THIRD: AMERICAN_FOOTBALL_MATCH_STATUS.END_THIRD_QUARTER,
  DELAYED: AMERICAN_FOOTBALL_MATCH_STATUS.DELAYED,
  OVERTIME: AMERICAN_FOOTBALL_MATCH_STATUS.OVERTIME,
  FIRST: AMERICAN_FOOTBALL_MATCH_STATUS.FIRST_QUARTER,
  SECOND: AMERICAN_FOOTBALL_MATCH_STATUS.SECOND_QUARTER,
  THIRD: AMERICAN_FOOTBALL_MATCH_STATUS.THIRD_QUARTER,
  FOURTH: AMERICAN_FOOTBALL_MATCH_STATUS.FOURTH_QUARTER,
  OTHER: MATCH_STATUS_GENERAL.OTHER,
  NOT_STARTED: MATCH_STATUS_GENERAL.NOT_STARTED,
  CANCELLED: MATCH_STATUS_GENERAL.CANCELLED
};

export default MATCH_STATUS;
