// MATCHES
export const FETCHING_MATCHES = 'FETCHING_MATCHES';
export const FETCHING_MATCHES_SUCCESS = 'FETCHING_MATCHES_SUCCESS';
export const FETCHING_MATCHES_ERROR = 'FETCHING_MATCHES_ERROR';

// BETS
export const FETCHING_USER_BETS = 'FETCHING_USER_BETS';
export const FETCHING_USER_BETS_SUCCESS = 'FETCHING_USER_BETS_SUCCESS';
export const FETCHING_USER_BETS_ERROR = 'FETCHING_USER_BETS_ERROR';
export const UPDATING_REGULAR_BET = 'UPDATING_REGULAR_BET';
export const UPDATING_REGULAR_BET_SUCCESS = 'UPDATING_REGULAR_BET_SUCCESS';
export const UPDATING_REGULAR_BET_ERROR = 'UPDATING_REGULAR_BET_ERROR';

// EXTRA BETS
export const FETCHING_EXTRA_BETS = 'FETCHING_EXTRA_BETS';
export const FETCHING_EXTRA_BETS_SUCCESS = 'FETCHING_EXTRA_BETS_SUCCESS';
export const FETCHING_EXTRA_BETS_ERROR = 'FETCHING_EXTRA_BETS_ERROR';
export const UPDATING_EXTRA_BETS = 'UPDATING_EXTRA_BETS';
export const UPDATING_EXTRA_BETS_SUCCESS = 'UPDATING_EXTRA_BETS_SUCCESS';
export const UPDATING_EXTRA_BETS_ERROR = 'UPDATING_EXTRA_BETS_ERROR';

// USER LOGIN
export const FETCHING_LOGIN = 'FETCHING_LOGIN';
export const FETCHING_LOGIN_SUCCESS = 'FETCHING_LOGIN_SUCCESS';
export const FETCHING_LOGIN_ERROR = 'FETCHING_LOGIN_ERROR';

// USER UPDATE
export const UPDATING_USER = 'UPDATING_USER';
export const UPDATING_USER_SUCCESS = 'UPDATING_USER_SUCCESS';
export const UPDATING_USER_ERROR = 'UPDATING_USER_ERROR';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

// USER REGISTER
export const REGISTERING = 'REGISTERING';
export const REGISTERING_SUCCESS = 'REGISTERING_SUCCESS';
export const REGISTERING_ERROR = 'REGISTERING_ERROR';

// USER LOGOUT
export const FETCHING_LOGOUT = 'FETCHING_LOGOUT';
export const FETCHING_LOGOUT_SUCCESS = 'FETCHING_LOGOUT_SUCCESS';
export const FETCHING_LOGOUT_ERROR = 'FETCHING_LOGOUT_ERROR';

// CONFIG
export const FETCHING_CONFIG = 'FETCHING_CONFIG';
export const FETCHING_CONFIG_SUCCESS = 'FETCHING_CONFIG_SUCCESS';
export const FETCHING_CONFIG_ERROR = 'FETCHING_CONFIG_ERROR';
export const SET_CURRENT_WEEK = 'SET_CURRENT_WEEK';

export const FETCHING_RANKING = 'FETCHING_RANKING';
export const FETCHING_RANKING_SUCCESS = 'FETCHING_RANKING_SUCCESS';
export const FETCHING_RANKING_ERROR = 'FETCHING_RANKING_ERROR';
export const FETCHING_SEASON_RANKING = 'FETCHING_SEASON_RANKING';
export const FETCHING_SEASON_RANKING_SUCCESS =
  'FETCHING_SEASON_RANKING_SUCCESS';
export const FETCHING_SEASON_RANKING_ERROR = 'FETCHING_SEASON_RANKING_ERROR';

export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// RECORDS
export const FETCHING_RECORDS = 'FETCHING_RECORDS';
export const FETCHING_RECORDS_SUCCESS = 'FETCHING_RECORDS_SUCCESS';
export const FETCHING_RECORDS_ERROR = 'FETCHING_RECORDS_ERROR';
