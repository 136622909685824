export const STATUS = {
  SUCCESS: 0,
  ERROR: 1,
  WARNING: 2,
  INFO: 3
};

export const SEVERITY = {
  [STATUS.SUCCESS]: 'success',
  [STATUS.ERROR]: 'error',
  [STATUS.WARNING]: 'warning',
  [STATUS.INFO]: 'info'
};
